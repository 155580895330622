import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";


const AboutPage = () => (
    <Layout>
        <Seo title={'About Us | Voted Best Secured Loan Provider | Nemo Personal Finance '} description={'Nemo Personal Finance was voted the best secured loan provider of 2014. Visit us online today and find out why. '} lang={'en-gb'} />
        <div className="twoCol inrThreeCol cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul></ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="bdr cupBg">
                    <div className="bdrTop"></div>
                    <div className="bdrMid cfx">
                        <div className="headingArea cfx">
                            <div className="bubble blue cfx">
                                <div className="inr">
                                    <h1>About us</h1>
                                </div>
                                <div className="bot"></div>
                            </div>
                            <div className="intro">
                                <p>We opened our doors in 2005 to
                                    offer secured loans for UK homeowners with an existing mortgage..</p>
                                <p>We are committed to providing you
                                    with excellent service while you have a Nemo second charge mortgage. </p>
                                <p>While we no longer offer new second
                                    charge mortgages, or further advances, we remain 100% committed to providing you
                                    with exceptional service.</p>
                                <p></p>
                                <p></p>
                                <div className="taR"><p></p></div>
                            </div>
                        </div>
                    </div>
                    <div className="bdrBot"></div>
                </div>
                <div className="threeCol cfx">
                    <div className="col col1">
                        <div className="fb1">
                            <div className="top"></div>
                            <div className="mid about-us-boxes"> {/* adding class to even out height of all three */}
                                <h2>Our History</h2>
                                <p>We opened our doors in 2005 to offer secured loans for UK homeowners with an existing mortgage.</p>
                                <p>Nemo Personal Finance is a valued subsidiary 
                                    of the <a href="https://www.principality.co.uk/about-us/your-principality/our-history">Principality Building Society Group</a> </p>
                            </div>
                            <div className="bot"></div>
                        </div>
                    </div>
                    <div className="col col2">
                        <div className="fb1">
                            <div className="top"></div>
                            <div className="mid about-us-boxes">
                                <h2>Charity Partnerships</h2>
                                <p>Alongside Principality Building Society, Nemo is proud to support charity partners
                                that are selected by our colleagues.</p>
                                <p>Please visit the <a href="https://www.principality.co.uk/about-us/our-community/charity-partnerships">Principality Charity Partner page</a> to 
                                find out more about our charity partners and the work we do to support them.</p>
                                <ul className="newsList"></ul>
                                {/*<p><a href="" className="arr">View all news stories</a></p>*/}
                            </div>
                            <div className="bot"></div>
                        </div>
                    </div>
                    <div className="col col3">
                        <div className="fbList">
                            <ul>
                                <li className="plant about-us-boxes" id="workForUsFb">
                                    <div className="top"></div>
                                    <div className="txtWrap">
                                        <h2>Work for us</h2>
                                        <p>
                                            Find out about career opportunities at Nemo and Principality Building Society 
                                            on the <a href="https://www.principality.co.uk/careers">careers page</a>
                                        </p>
                                    </div>
                                    <div className="linkWrap">
                                        <p></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
)

export default AboutPage;